<template>
    <div class="videoModel" v-if="videoShow">
        <div class="videoBox">
            <i class="el-icon-close" @click="onClose"></i>
            <live-player :video-url="videoUrl" live="false" aspect="false" stretch="false" muted="false"></live-player>

            <!-- <video width="320" height="240" controls="controls" controlsList="nodownload" autoplay>
                <source :src="videoUrl" type="video/mp4" />
            
            </video> -->
        </div>
    </div>
</template>

<script>
export default {
    name:'videoModel',
    data(){
        return {
            videoShow:false,
            videoUrl:''
        }
    },
    methods:{
        onClose(){
            this.videoShow = false
            this.videoUrl = ''
        }
    }
}
</script>

<style scoped>
.videoModel{
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    z-index:1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.videoBox{
    width:10rem;
    height:5.6rem;
    background-color:#fff;
    position: relative;
}
.el-icon-close{
    position: absolute;
    top:-0.5rem;
    right:-0.5rem;
    font-size:0.3rem;
    color:#fff;
    cursor: pointer;
}
</style>